.content-wrapper {
  display: flex;
  padding-right: 40px;
  width: 100%;
  margin-bottom: 100px;
}

.section-wrapper {
  flex: 1;
  position: relative;
}

.right-panel {
  background: #fff;
  border-radius: 20px;
  max-width: 400px;
  max-height: 350px;
  margin-left: 20px;
  padding: 20px;
  z-index: 1;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03), 0 6px 6px rgba(0, 0, 0, 0.03);

  .date {
    cursor: pointer;
    text-align: center;
    width: 24px;
    height: 24px;
    transition: all 0.3s;
    border-radius: 4px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: #e6f7ff;
    }

    &.today {
      color: #fff;
      background: #1890ff;
      box-shadow: 0 0 0 1px #1890ff inset;
    }

    &.paid {
      border: 1px solid #e2e2e2;
    }

    &.unpaid {
      border: 1px solid #eb2f96;
    }
  }
}

.dashboard {
  background: #fff;
  padding: 12px 24px;
  min-height: 240px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03), 0 6px 6px rgba(0, 0, 0, 0.03);
}

.app-container {
  background: #fff;
  padding: 24px;
  min-height: 240px;
  border: 1px solid #e8e8e8;
}

.notes-month {
  border: 1px solid black;
}
