.login-page {
  background: #ebf0f0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.login-wrapper {
  position: relative;
  top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.splash-text {
  font-size: 60px;
  margin-bottom: 50px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 2px;
}

.login-button {
  max-width: 200px;
}
