.app {
  background: #ebf0f0;
  width: 100vw;
  height: 100vh;
}

.authenticated-wrapper {
  background: #ebf0f0;
  $sidebar-width: 300px;
  position: relative;
  padding-top: 60px;
  display: flex;

  > .sidebar-container {
    position: fixed;
    height: 100vh;
    width: $sidebar-width;
  }

  > .content {
    left: $sidebar-width;
    position: relative;
    width: calc(100% - #{$sidebar-width});
  }
}
