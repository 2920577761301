.app-wrapper {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}

.bill-list {
  margin: 0 auto;
}
