.dashboard-header {
  width: 100%;
  background-color: #fff;
  display: flex;
  margin-bottom: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03), 0 6px 6px rgba(0, 0, 0, 0.03);
}

.dashboard-card-wrapper {
  margin-bottom: 10px;
}

.dashboard-card {
  font-size: 28px;
  min-width: 150px;
}

.dashboard-header-card {
  margin: 10px 0;
  padding: 15px 25px;
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:not(:first-child) {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  > .header {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
  }

  > .content {
    font-size: 24px;
  }
}
