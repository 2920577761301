.sidebar {
  margin: 0 30px;

  > .header {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    font-size: 30px;
  }

  > .profile-image {
    height: 80px;
    box-shadow: 0 0 0 3px #107dac;
    border: 3px solid transparent;
    margin: 25px 15px 10px;
    border-radius: 50%;
  }

  > .name {
    font-size: 24px;
  }

  > .nav {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .nav-button {
      display: flex;
      color: #555;
      align-items: center;
      border-radius: 200px;
      padding: 15px;
      width: 230px;
      margin: 5px 0;

      transition: all 0.2s ease-in-out;

      > i {
        font-size: 20px;
        margin-right: 10px;
      }

      &:hover {
        > i {
          color: #107dac;
        }
      }

      &.active {
        background: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.034),
          0 1px 2px rgba(0, 0, 0, 0.034);

        > i {
          color: #107dac;
        }
      }
    }
  }
}
