.list-header {
  font-weight: bold;
}

.app-section-container {
  margin-right: 40px;
}

.app-section-content {
  padding: 24px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03), 0 6px 6px rgba(0, 0, 0, 0.03);
}

.bills-list {
  min-height: 200px;
  transition: all 0.3s;

  &.paid-list {
    padding-bottom: 24px;
  }

  .item {
    font-size: 20px;
    min-height: 82px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      font-size: 16px;
      min-height: 60px;
    }

    &:hover {
      background: #f2f4f4;
    }

    .bill-due-date {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 60px;
      font-weight: bold;
      font-size: 16px;
    }

    .bill-source {
      margin: 0 30px;
      flex: 1;
    }
  }

  .paid {
    color: lightgray;
  }
}
